import React from "react"
import { graphql } from 'gatsby'
// import ReactMarkdown from 'react-markdown'
import LayoutPublic from "../components/layouts/layoutPublic"
import { Box, Button, Heading, Icon, SimpleGrid, Stack } from "@chakra-ui/react"
import Seo from '../components/Seo/';
import Header from "../components/header/index"
import AdsSummary from "../components/lists/adsSummary"
import AsyncAdsPanel from "../components/ad/AsyncAdsPanel"
import HrefLang from '../utils/HrefLang'
import WysiwygContent from "../components/content/content";
import StructuredProductComparison from '../components/StructuredData/productComparison'
import ModelAdsTable from '../components/ModelAdsTable'
import Wrapper from '../components/Wrapper'
import { Link as GatsbyLink } from 'gatsby'
import { FormattedMessage } from "react-intl"
import { BiRightArrow, BiRightArrowAlt } from "react-icons/bi";
import Nav from "../components/nav";
import Debug from '../utils/Debug'
import { wrapperPadding } from "../utils/wrapperPadding"

var markdown = require("markdown").markdown;

const BrandTpl = (props) => {
  const {
    descShort,
    name,
    model,
    refUnivers,
    singularName,
    illustration,
    slug,
  } = props.data.brand

  const {
    ads,
    brandOtherLang
  } = props.data


  const title = {
    "fr":
      name +
      " d'occasion à vendre, achat/vente"
    ,
    "en":
      "Used "
      + name +
      " for sale, price/buying/selling a boat"
  }
  const description = {
    "fr":
      name + " d'occasion au meilleur prix à vendre en France et en Europe, annonces prix, achat, vente. Wanaboat.fr spécialiste achat/vente " + name.toLowerCase() + " d'occasion."
    ,
    "en":
      "Used " + name + " classifieds ads at the best price for sale in Europe, price listings, buy, sell. iWannaboat.com specialist buy/sell used " + name.toLowerCase() + "."
  };

  return (
    <LayoutPublic lang={process.env.G}>
      {brandOtherLang ? <HrefLang lang="fr" slug={brandOtherLang.slug} /> : null}
      <Seo
        title={title[process.env.GATSBY_LANG]}
        description={description[process.env.GATSBY_LANG]}
        slug={slug}
      />


      <StructuredProductComparison
        name={name}
        lowPrice={2000}
        highPrice={10000}
        offerCount={ads ? ads.edges.length : 0}
        refBrand={name}
        slug={slug}
        images={
          ads.edges[0] && ads.edges[0].pictures
            ? ads.edges[0].node.pictures.url
            : null
        }
      />
      <Header
        nav={<Nav />}

        image={illustration}
        illuUrlQueryString={true}
        name={name}
        breadcrumbsItems={[
          refUnivers ? refUnivers : null,
          { "name": name, "slug": slug }
        ]
        }
        slug={slug}
        descShort={
          <Box
            className="wysiwyg"
            dangerouslySetInnerHTML={{
              "__html": markdown.toHTML(descShort.descShort)
            }}
          />
        }
      />
      <Stack
        spacing={4}
        {...wrapperPadding()}
      >

        <SimpleGrid
          my={{ base: '1rem', lg: '2rem' }}
          gap='2rem' columns={{ base: 1, lg: 2 }}
        >
          <Box>
            <Heading
              color="gray.800"
              textTransform='uppercase'
              fontWeight='normal'
              fontSize='13px'
              color='blue.600'
              letterSpacing='.05rem'
              mb='.5rem'
              as='h2'>
              <FormattedMessage id='brand.model.made.by' values={{ name: name }} /></Heading>
            <SimpleGrid
              columns={{ base: 2, lg: 3, xl: 3 }}
              gap={{ base: '1rem' }}
              mb='1rem'
            >
              {model ? model.map(model =>
                <Button
                  p='.45rem'
                  justifyContent='space-between'
                  to={`${model.slug}`}
                  as={GatsbyLink}
                  bg='white'
                  shadow='sm'
                  borderRadius='2px'
                  key={model.contentful_id}
                  variant='link'
                  rightIcon={<BiRightArrowAlt />}
                  position={'initial'}
                >
                  {model.name}
                </Button>
              ) : null}
            </SimpleGrid>
            <AdsSummary
              ads={ads.edges.filter((item, index) => index < 5 ? item : null)}
              universName={singularName}
              // orientation='portrait'
              columns={1}
            />
          </Box>
          <Box
            maxH={{ base: 'auto', lg: '650px' }}
            overflowY={{ base: 'auto', lg: 'scroll' }}
          >
            <Heading
              color="gray.800"
              textTransform='uppercase'
              fontWeight='normal'
              fontSize='13px'
              color='blue.600'
              letterSpacing='.05rem'
              mb='.5rem'
              as='h2'
            >
              <FormattedMessage id="brand.presentation.title" values={{ modelName: name }} />
            </Heading>
            <Box>
              {props.data.brand.description ?
                <WysiwygContent data={JSON.parse(props.data.brand.description.raw)} references={props.data.brand.references} />
                : null}
            </Box>
          </Box>
        </SimpleGrid>
        <AsyncAdsPanel
          baseSlug={slug}
          minPrice={0}
          maxPrice={200}
          brandID={props.pageContext.contentfulID}
          isModelsMobileOpen={false}
          scrolling={false}
          switchViewForBrowsing={() => { this.setState({ isUserFilters: true }) }}
        />
        {props.data.brand.description ?
          <Box
            p={{ base: '1rem', lg: '2rem' }}
            mt='2rem'
            bg='white'
          >
            <Heading as="h2" textTransform="uppercase" fontSize="18px" fontWeight="normal" color="blue.600" letterSpacing=".05rem" mb="1.5rem">
              <FormattedMessage id="model.price.table.title" values={{ modelName: name }} />
            </Heading>
            <ModelAdsTable ads={ads} hideBrand={false} modelName={name} links={false} />
          </Box>
          : null}

      </Stack>
    </LayoutPublic>
  )
}

export default BrandTpl

export const pageQuery = graphql`
  query brandQuery($slug: String!, $contentfulID:  String!, $otherLang: String!, $lang: String! ){
    brand: contentfulBrand(slug: {eq: $slug}) {
      slug
      name
      illustration{
        gatsbyImageData(
          width:600,
          height:600,
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
      refUnivers{ slug name }
      model{
        contentful_id
        name
        image
        slug
      }
      image {
        file {
          url
        }  
      }
      descShort {
        id
        descShort
      }
      description{
        raw
      }
    }
    brandOtherLang: contentfulBrand(
      contentful_id: {eq: $contentfulID}
      node_locale: { eq: $otherLang }
    ) {
      id
      slug
    }
    ads: allContentfulAd(
      filter: {
        refBrand: {slug: {eq: $slug}}
        node_locale: {eq: $lang}
      }
    ) {
      edges {
        node {
          contentful_id
          id
          name
          slug
          price
          department
          refBrand{
            name
          }
          images{
            url
          }
          pictures{ url }
          date(formatString: "Y")
          publicationDate(formatString: "D/M/Y")
          refUnivers{
            slug
          }
        }
      }
    }
  }
`